import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
export const HistoryPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  body,
  featuredImgGatsby
}) => (
  <main className="HistoryPage">
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
      featuredImgGatsby={featuredImgGatsby}
    />

    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
    </section>
  </main>
)

const HistoryPage = ({ data: { page } }) => {
  const featuredImgUrl =
    page.featuredImg && page.featuredImg.childImageSharp.fixed.src

  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
      ogImageUrl={featuredImgUrl || ''}
    >
      <HistoryPageTemplate
        {...page.frontmatter}
        body={page.html}
        featuredImgGatsby={page.featuredImg}
      />
    </Layout>
  )
}
export default HistoryPage

export const pageQuery = graphql`
  query HistoryPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed(height: 200, width: 200) {
            src
          }
        }
      }
    }
  }
`
